import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useI18next } from 'gatsby-plugin-react-i18next'
import styled from "styled-components"
import { media } from './Styles'

import more from "../images/nav/more.svg"

const NewsCard = ({ article }) => {
    const { language } = useI18next()

    const image = {
        image: getImage(article?.featuredImage?.node?.localFile),
        alt: article?.featuredImage?.node?.altText || '',
    }

    return (
        <StyledNews>
            <div className='news-image'>
                {image?.image &&
                    <GatsbyImage image={image.image} alt={image.alt || ''} />
                }
            </div>
            <div className='content'>
                <h3 className={"title"}>
                    {article?.title}
                </h3>
                <p className={"excerpt"}  dangerouslySetInnerHTML={{ __html: article?.excerpt}}>
                </p>
                <Link to={"/noticias" + article?.uri}>
                    {language === 'pt' ? 'LER NOTÍCIA COMPLETA' : 'READ THIS ARTICLE'}
                    <img src={more} alt="More"/>
                </Link>
            </div>
        </StyledNews>
    )
}

export default NewsCard

const StyledNews = styled.article`
    max-width:1600px;
    margin:0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:5%;

    ${media.xl`
        flex-wrap:wrap;
        gap:3rem;
    `}

    ${media.smarttv`
        gap:0;
    `}

    .news-image{
        width:100%;

        .image{
            max-height:70vh;
            box-shadow: 10px 10px 22px -13px rgba(0,0,0,0.75);
        }
    }

    .content{
        width:100%;
        text-align:left;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;

        ${media.smarttv`
            gap:0;
            margin-left:5%;
        `}

        .title{
            text-transform:uppercase;
            font-size: 2.4rem;
            font-weight: 400;
        }

        .excerpt{
            font-size: 1.3rem;
            line-height: 1.8rem;
            font-weight: 400;

            ${media.smarttv`
                margin:2rem 0;
            `}
        }

        a{
            text-transform:uppercase;
            font-size: 1.3rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 1rem;

            ${media.smarttv`
                gap:0;
            `}

            img{
                max-width:40px;
                border-radius: 50%;
                transition: 0.3s;

                ${media.smarttv`
                    margin-left:1rem;
                `}

                :hover{
                    background: #ebebeb80;
                }
            }
        }
    }

`
