import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { connect } from 'react-redux'
import styled from "styled-components"
import { media } from '../components/Styles'
import { toggleWhiteSidebar } from "../state/app"
import { InView } from 'react-intersection-observer'
import Sidebar from "../components/homeMenu/sideBar"
import MainBanner from '../components/MainBanner'
import BannerHome from '../components/BannerHome'
import NewsBanner from '../components/NewsBanner'
import { Image } from '../components/Images'
import Form from "../components/FormCasa"


const IndexPage = ({ news, dispatch, location, data }) => {
    const { t } = useTranslation()

    var articles = data.allWpPost.nodes

    var dataHome = t("home", { returnObjects: true })
    var dataGlobal = t("global", { returnObjects: true })

    return (
        <Layout
            data={dataGlobal}
            hideForm
            location={location}
        >
            <Seo title="Ponto Urbano" defTitle />
            <Image
                src={'home/bg.png'}
                alt='Ponto Urbano'
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1
                }}
            />
            <Sidebar
                content={dataGlobal.sidebar}
                projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
                projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
                whiteMobileNavbar
            />
            {/* CHANGE SIDE BAR COLOR TO WHITE */}
            <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
                <MainBanner
                    data={dataHome.menu}
                    pt2020
                    projects
                />
            </InView>
            <BannerHome
                data={dataHome.textBanner}
                images={dataHome.images}
            />
            {/* CHANGE SIDE BAR COLOR TO WHITE */}
            <InView as="div" threshold={0.2} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
                <BottomStyled>
                    <div className='bg-image'>
                        <Image
                            src={'home/bg2.png'}
                            alt='Ponto Urbano'
                        />
                    </div>
                    <NewsBanner
                        articles={articles}
                        title={dataHome.news.lastNewTitle}
                    />
                    <Form
                        data={dataGlobal.form}
                        transparent
                        title
                        msgContent={dataGlobal.msgMarkPhone}
                    />
                </BottomStyled>
            </InView>
        </Layout>
    )
}

export default connect(state => ({
    news: state.app.news
}), null)(IndexPage)


const BottomStyled = styled.div`
    position:relative;
    padding-top:35vh;

    ${media.xl`
        padding-top:unset;
    `}

    .bg-image{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .image{
            height:100%;
        }

        ::before{
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color: #303030;
            opacity: 0.98;
            z-index: 1;
        }
    }
`

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    allWpPost(sort: {fields: [date], order: DESC}, limit: 2) {
        nodes {
          uri
          date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
          title
          content
          excerpt
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [JPG]
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
  }
`