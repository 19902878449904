import React, { useState } from "react"
import { Link } from "gatsby"
import { Image } from './Images'
import { media } from './Styles'
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { CSSTransition } from 'react-transition-group'

import more from "../images/nav/more.svg"

const Images = ({ data }) => {
    const breakpoints = useBreakpoint()

    const [projectInfo, setProjectInfo] = useState(false)
    const [projectKey, setProjectKey] = useState(null)

    return (
        <StyledImages>
            <div className='wrapper'>
                {data.map((image, key) => (
                    <Link
                        className='project-wrapper'
                        to={image.link}
                        key={key}
                    >
                        <Image
                            src={image.image}
                            alt={image.alt}
                            onMouseEnter={() => { setProjectInfo(true); setProjectKey(key) }}
                            className="bg-image"
                        />
                        <CSSTransition in={(projectInfo && projectKey === key) || breakpoints.l} 
                        appear={(projectInfo && projectKey === key) || breakpoints.l}
                        timeout={250} 
                        onMouseLeave={() => { setProjectInfo(false); setProjectKey(null) }}
                        classNames="animation" unmountOnExit>
                            <div className='overlay'  >
                                <h4 className='city' dangerouslySetInnerHTML={{ __html: image.city }}></h4>
                                {image.title &&
                                    <h3 className='project' dangerouslySetInnerHTML={{ __html: image.title }}></h3>
                                }
                                {image.logo &&
                                    <Image
                                        src={image.logo}
                                        alt={image.alt ? image.alt : ''}
                                        onMouseEnter={() => { setProjectInfo(true); setProjectKey(key) }}
                                    />
                                }
                                <img src={more} alt="More" className='more' />
                            </div>
                        </CSSTransition>
                    </Link>
                ))}
            </div>
        </StyledImages>
    )
}

export default Images

const StyledImages = styled.div`
    .wrapper{
        display:flex;
        max-width:1600px;
        margin:0 auto;
        height: 80vh;

        ${media.l`
            flex-direction:column;
        `}
        @media (min-width: 1990px){
            height: 75vh;
        }
        .project-wrapper{
            width:100%;
            position:relative;
            z-index:1;

            .bg-image{
                height: 100%;
                img{
                    object-position: right top!important;
                }
            }

            .image{
                max-height:80vh;
                z-index:2;

                ${media.l`
                    max-height:35vh;
                `}
            }

            .overlay{
                position:absolute;
                width:100%;
                height:100%;
                top:0;
                left:0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 3rem 5%;
                background-color:#00000080;
                color:#ebebeb;
                text-transform:uppercase;
                z-index:3;

                .city{
                    font-size:1.2rem;
                }

                .project{
                    font-weight:400;
                    font-size:2rem;
                }

                .more{
                    max-width:45px; 
                    border-radius: 50%;
                    transition: 0.3s;

                    :hover{
                        background: #ebebeb80;
                    }
                }

                .image{
                    width:100%;
                    max-width:15vw;

                    ${media.l`
                        max-width:40vw;
                    `}
                }
            }
        }
    } 

    // ANIMAÇÕES
    .animation-enter {
        opacity: 0;
    }
    .animation-enter-active {
        opacity: 1;
        transition: opacity 250ms;
    }
    .animation-exit {
        opacity: 1;
    }
    .animation-exit-active {
        opacity: 0;
        transition: opacity 250ms;
    }
`
