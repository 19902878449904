import React from "react"

import styled from "styled-components"
import { useI18next } from 'gatsby-plugin-react-i18next'
import NewsCard from "./NewsCard.js"
import { media } from './Styles'

const NewsList = ({ articles, title }) => {
    const { language } = useI18next()

    // FILTRAR OS ARTICLES CONSOANTE A LINGUA SELECTIONADA
    const filteredArticles = articles.filter(function (article) {
        let articleLanguage = ''

        article.tags.nodes.map(slug => (
            articleLanguage = slug.slug
        ))

        return articleLanguage === language
    })

    // GET LAST ARTICLE
    var lastArticle = filteredArticles.slice(0, 1).shift()

    return (
        <StyledNews>
            <div className='wrapper'>
                <div className='line'></div>
                <div className='main-title'>{title}</div>
                <NewsCard article={lastArticle} />
                <div className='line bottom'></div>
            </div>
        </StyledNews>
    )
}

export default NewsList

const StyledNews = styled.div`
    position:relative;

    .wrapper{
        margin: 0 7.3%;
        padding: clamp(50px,10vw,90px) 0 0 0;
        color:#ebebeb;

        .line{
            height:125px;
            width:50%;
            border-right: 1px solid #fff;
            margin: 0 0 clamp(50px,10vw,90px) 0;  
            
            ${media.m`
                height:50px;
            `}
        }

        .line.bottom{
            margin: clamp(50px,10vw,90px) 0 0 0;
        }
        

        .main-title{
            font-weight:600;
            font-size:2.5rem;
            text-align:center;
            padding-bottom: clamp(50px,10vw,90px);
        }
    }
`
